/* It is better to connect the fonts in the css file rather than GlobalStyle.ts because it prevents font jumping */

@font-face {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    src: url('assets/fonts/Inter-Light.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url('assets/fonts/Inter-Regular.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: url('assets/fonts/Inter-Medium.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: url('assets/fonts/Inter-SemiBold.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url('assets/fonts/Inter-Bold.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: url('assets/fonts/Inter-ExtraBold.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: url('assets/fonts/Inter-Black.woff2');
}

@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/amatic-sc-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/amatic-sc-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/amatic-sc-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/amatic-sc-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/roboto-slab-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/roboto-slab-500.woff2') format('woff2');

}

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/roboto-slab-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/roboto-slab-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/playfair-display-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/playfair-display-500.woff2') format('woff2');
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/playfair-display-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/playfair-display-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Kalam';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/kalam-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Kalam';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/kalam-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Kalam';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/kalam-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Kalam';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/kalam-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/oswald-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/oswald-500.woff2') format('woff2');
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/oswald-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/oswald-700.woff2') format('woff2');
}

body {
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    color: #000000;
}

.cc-revoke.cc-bottom.cc-right, .cc-highlight .cc-btn:first-child:hover {
    text-decoration: none;
}

